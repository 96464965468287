import React from 'react';
import { connect } from 'react-redux';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Button, Container, FormInput } from '../../common/styled/form';
import { actionCreators } from '../../actions/user';
import { Container as GuestLayout } from '../app/GuestLayout/styled';
import { AppState } from '../../store/interfaces';
import Modal from '../../common/Modal';

import { InfoTextStyled, AgreementStyled, PhoneInputContainer, PhoneInputHelper } from './styled';
import { RegistrationProps, RegistrationState } from './interfaces';

import styled, { CSSProperties } from 'styled-components';
import { BestRides } from './BestRides';

const CenterText = styled.section`
  padding: 0 20px;

  @media (max-width: 768px) {
    padding: 0 12px;
  }

  p {
    text-align: center;
    font-size: 18px;
    line-height: 24px;
  }
  a,
  a:visited,
  a:hover {
    cursor: pointer;
    font-weight: bold;
    color: #de308d;
  }
  h1 {
    font-size: 32px;
    font-weight: bold;
    margin: 0 0 15px 0;
    color: rgb(247, 183, 48);
    line-height: 32px;
  }

  h2 {
    font-size: 32px;
    font-weight: bold;
    margin: 0 0 15px 0;
    color: #de318c;
    line-height: 32px;
  }

  h3 {
    font-size: 24px;
    line-height: 30px;
    font-weight: 400;
    color: #f7b730;
  }

  div,
  h1,
  h2 {
    text-align: center;
  }

  ol {
    list-style: none;
    counter-reset: li;
  }

  ol li::before {
    content: counter(li) '.';
    color: #f7b72f;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }

  ol li {
    counter-increment: li;
  }

  footer {
    color: #999999;
    font-size: 15px;
    font-weight: 300;
    line-height: 1.8em;
    text-align: center;
  }
`;

const Background = styled.div`
  position: relative;
  background: #151515;

  &:before {
    position: absolute;
    opacity: 0.15;
    background: url(/assets/css/background.jpg) 50% 0 no-repeat;
    background-size: cover;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media (max-width: 768px) {
    h2,
    h1 {
      font-size: 28px;
      line-height: 34px;
    }

    p {
      font-size: 16px;
      line-height: 20px;
    }

    h3 {
      font-size: 22px;
      line-height: 28px;
    }

    ul,
    ol {
      padding-left: 20px;
      font-size: 16px;
    }
  }
`;

const yellowStyle: CSSProperties = { color: '#f7b72f', fontWeight: 'bold' };

class DriverRegistration extends React.Component<
  RegistrationProps & WithTranslation,
  RegistrationState
> {
  state: RegistrationState = {
    phone_number: process.env.NODE_ENV === 'production' ? '' : '7328474341',
    license_number: process.env.NODE_ENV === 'production' ? '' : 't48190420012580',
    referrer_code: window.location.pathname ? window.location.pathname.slice(1) : '',
    verification_code: '',
  };

  componentWillUnmount() {
    const { clear } = this.props;
    clear();
  }

  submitFormWithValidationCode = () => {
    const { verification_code } = this.state;
    this.submitForm(null, verification_code);
  };

  submitForm = (event, verification_code: string | null = null) => {
    const { phone_number, license_number, referrer_code } = this.state;
    const { registration, registrationConfirmSms, isLoading } = this.props;

    if (isLoading) {
      return;
    }

    const data = {
      phone_number: `+1${phone_number.replace(/\D/g, '')}`,
      license_number: license_number.replace(/\W/g, ''),
      referrer_code: referrer_code.trim(),
    };

    if (verification_code !== null) {
      // @ts-ignore
      data.verification_code = verification_code;
      registrationConfirmSms(data);
      return;
    }

    registration(data);
  };

  setValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === 'phone_number') {
      this.setState({
        phone_number: this.getNumberValue(value),
      });
      return;
    }

    // @ts-ignore
    this.setState({
      [name]: value,
    });
  };

  handlePastePhone = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const value = e.clipboardData.getData('Text');

    this.setState({
      phone_number: this.getNumberValue(value),
    });
  };

  getNumberValue = (value: string) => {
    if (value[0] === '+' && value[1] === '1') {
      return value.substring(2, value.length);
    }

    return value;
  };

  render() {
    const { phone_number, license_number, verification_code } = this.state;
    const { verificationCode, errors, isLoading, cleanVerificationCode, userId, t } = this.props;

    return (
      <GuestLayout showLogo={false}>
        <Background>
          <Container>
            {verificationCode && (
              <Modal
                onApply={this.submitFormWithValidationCode}
                applyText={
                  isLoading ? t('driverRegistration.validating') : t('driverRegistration.validate')
                }
                cancelText="Cancel"
                onClose={cleanVerificationCode}
              >
                <InfoTextStyled style={{ color: '#fff' }}>
                  {t('driverRegistration.smsValidationText')}
                </InfoTextStyled>
                <FormInput
                  placeholder={t('driverRegistration.validationCodePlaceholder')}
                  value={verification_code || ''}
                  name="verification_code"
                  onChange={this.setValue}
                  inputStyle={{ textAlign: 'center' }}
                  error={errors.verification_code}
                />
              </Modal>
            )}
            <CenterText>
              <h2 style={{ color: '#DE308D' }}>
                Welcome to <span style={yellowStyle}>Skeddy!</span>
              </h2>
              <p>
                You were referred to us by our partner, so you get a{' '}
                <span style={yellowStyle}>5 Ride Credits</span> bonus!
              </p>
              <h3>
                <span style={yellowStyle}>What does Skeddy do:</span>
              </h3>
              <ul>
                <li>
                  Skeddy discovers long-length Lyft rides in advance and combines them for you into
                  a profitable chain trip!
                </li>
                <li>
                  With Skeddy, you choose from multiple available rides in advance, and build your
                  desired driving schedule, which is convenient for you!
                </li>
                <li>Skeddy allows you to plan your Lyft pickups for up to a week ahead!</li>
                <li>
                  Skeddy maximizes your daily pay while cutting off your behind-the-wheel time!
                </li>
              </ul>
              <h2 style={{ marginTop: 30 }}>
                Skeddy finds you Lyft jobs for up to <span style={yellowStyle}>$5K</span> weekly
              </h2>
              <h3>Check out our best scores:</h3>
              <BestRides />
              <h3>How does Skeddy do it:</h3>
              <ol>
                <li>
                  With Skeddy, you connect to Lyft using your Lyft credentials: see the{' '}
                  <a
                    style={{ position: 'relative' }}
                    target="_blank"
                    href="https://skeddy.net/step-by-step-guide/#guide"
                  >
                    Step-by-Step Guide
                  </a>
                  .
                </li>

                <li>
                  In the Skeddy app settings, you specify your work hours and the minimal ride
                  payoff you agree to take.
                </li>

                <li>
                  While you are in bed or busy with something else, Skeddy app will search for
                  scheduled Lyft rides fitting your specs, assign them to you on Lyft, and show them
                  in the list, so you could pick the best ones and cancel others.
                </li>
              </ol>
            </CenterText>
          </Container>
        </Background>

        <Container>
          <CenterText>
            <h1 style={{ marginTop: 10 }}>Sign up with Skeddy:</h1>
            <span style={yellowStyle}>Important:</span> with Skeddy, you connect to Lyft using your
            Lyft credentials: see the
            <a target="_blank" href="https://skeddy.net/step-by-step-guide/#guide">
              {' '}
              Step-by-Step Guide
            </a>
            .
            <PhoneInputContainer>
              <PhoneInputHelper>+1</PhoneInputHelper>
              <FormInput
                placeholder={t('driverRegistration.phonePlaceholder')}
                value={phone_number}
                name="phone_number"
                onChange={this.setValue}
                onPaste={this.handlePastePhone}
                error={errors.phone_number}
              />
            </PhoneInputContainer>
            <FormInput
              placeholder={t('driverRegistration.driveLicencePlaceholder')}
              value={license_number}
              name="license_number"
              onChange={this.setValue}
              error={errors.license_number}
            />
            <InfoTextStyled
              style={{ margin: '20px 12px 10px', fontSize: 18, color: 'rgb(247, 183, 48)' }}
            >
              {t('driverRegistration.agreementTitle')}
            </InfoTextStyled>
            <AgreementStyled>
              <p>{t('driverRegistration.agreement')}</p>
              <p>{t('driverRegistration.agreement1')}</p>
              <p>{t('driverRegistration.agreement2')}</p>
              <p>{t('driverRegistration.agreement3')}</p>
              <p>{t('driverRegistration.agreement4')}</p>
            </AgreementStyled>
            <p style={{ textAlign: 'center', fontSize: 16 }}>{t('driverRegistration.confirm')}</p>
            <div style={{ textAlign: 'center' }}>
              <Button onClick={this.submitForm}>{t('driverRegistration.continue')}</Button>
            </div>
          </CenterText>
          <CenterText>
            <h3 style={{ marginBottom: 5 }}>Have questions?</h3>
            <div
              style={{
                display: 'inline-block',
                position: 'relative',
                left: '50%',
                transform: 'translate(-50%, 0)',
                textAlign: 'left',
                marginBottom: 20,
              }}
            >
              <ul
                style={{
                  display: 'inline-block',
                }}
              >
                <li>
                  See the{' '}
                  <a target="_blank" href="https://skeddy.net/step-by-step-guide/#guide">
                    Step-by-Step Guide
                  </a>
                </li>

                <li>
                  Read{' '}
                  <a
                    target="_blank"
                    href="https://skeddy.zendesk.com/hc/en-us/articles/360053072553"
                  >
                    Skeddy General Guidance
                  </a>
                </li>

                <li>
                  Watch{' '}
                  <a target="_blank" href="https://youtu.be/xFgzsTHGQIE">
                    video tutorial
                  </a>
                </li>

                <li>
                  Visit our{' '}
                  <a target="_blank" href="https://skeddy.net/">
                    website
                  </a>{' '}
                  for more info
                </li>

                <li>
                  Follow our{' '}
                  <a
                    href="https://www.facebook.com/groups/skeddyappforlyftdrivers/"
                    target="_blank"
                  >
                    group
                  </a>{' '}
                  on Facebook
                </li>

                <li>
                  Visit{' '}
                  <a href="https://skeddy.zendesk.com/" target="_blank">
                    {' '}
                    Support Portal
                  </a>
                </li>

                <li>
                  Contact{' '}
                  <a href="https://skeddy.zendesk.com/hc/en-us/requests/new" target="_blank">
                    {' '}
                    Help Desk
                  </a>
                </li>
              </ul>
            </div>
            <footer>© 2021 Skeddy Technologies Inc. 11 Broadway, New York, NY 10004</footer>
          </CenterText>
        </Container>
      </GuestLayout>
    );
  }
}

const mapStateToProps = ({ auth, userForms: { registration } }: AppState) => ({
  errors: registration.errors,
  isLoading: registration.isLoading,
  verificationCode: registration.verificationCode,
  userId: auth.user?.user_id,
});

const mapDispatchToProps = {
  cleanVerificationCode: actionCreators.cleanVerificationCode,
  registration: actionCreators.registration.request,
  registrationConfirmSms: actionCreators.registrationConfirmSms.request,
  clear: actionCreators.registration.clear,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DriverRegistration));
